<template>
  <div v-loading="aloading" class="contnet">
    <!-- <p>微信授权结果</p>
    <p class="authBtn">{{ show_msg }}</p>
    <p class="authBtn">{{ redirect_url }}</p>
    <p class="authBtn">{{ auth_code }}</p>
    <p class="authBtn">{{ openid }}</p> -->
    <p class="doneBtn" @click="clickDone">点击完成授权</p>
  </div>
</template>

<script>
import { getWxCodeOpenid } from '@/api/edu_api.js'

export default {
  data() {
    return {
      show_msg: window.location.href,
      redirect_url: this.$route.query.auth_redirect,
      auth_code: this.$route.query.code,
      openid: '', // 通过后台交换code获取openid
      headimgurl: '', // 通过后台交换code获取 headimgurl
      nickname: '', // 通过后台交换code获取 nickname
      aloading: false
    }
  },
  created() {
    this.getOpenIdFunc()
  },
  methods: {
    getOpenIdFunc() {
      this.aloading = true
      const query = {
        code: this.auth_code
      }
      getWxCodeOpenid(query).then(res => {
        // {
        //   "access_token": "83_7XRLpuoCN7CryV-KQZB1c04AsNfm0dZnMC70QT1RKG-pZEQgxcMxoSlE1JyODaiK3lYuD-AbC4GyptaNKQCxR2uGpXC3YwkogApEUkbNRe8",
        //   "expires_in": 7200,
        //   "refresh_token": "83_2Vb1C0RJEJwzNnRC99EjYd7CvOwTAOiUgorp2-SsotOv23-174oNgQDowhy2ue59MmwzX1e5-_D9JGvzBYjkHrgICkxX2Ij5Ysj9a5fBGMY",
        //   "openid": "obuC56PYlFDQGTB9fYwSvlzUvMeI",
        //   "scope": "snsapi_userinfo",
        //   "unionid": "oI1Wb6DTHFPx6rWKrJliG0ctahZc",
        //   "headimgurl": "https:\/\/thirdwx.qlogo.cn\/mmopen\/vi_32\/ZPlWvlwyPTaRdqdiaVfM3NKUIHKWicwfoR2MKzrYnMiaMAU0Ulm0qSdyBE3V6ud9uu0ic93x2cEnicmJILRPibbJQ1gibDOBCbEpFlibiaI1kasmP2Hk\/132",
        //   "nickname": "\u90e8\u843d\u51b2\u7a81"
        // }
        const result = JSON.parse(res)
        if (result.openid) {
          this.openid = result.openid || ''
          this.headimgurl = result.headimgurl || ''
          this.nickname = result.nickname || ''
          this.$message.success('授权成功')
        } else {
          this.$message.error('授权失败')
        }
        this.aloading = false
      }).catch(() => {
        this.aloading = false
      })
    },
    clickDone() {
      // redirect_url
      var url = decodeURIComponent(this.redirect_url)
      if (url.includes('?')) {
        url = url + '&openid=' + this.openid + '&headimgurl=' + this.headimgurl + '&nickname=' + this.nickname + '&authback=1'
      } else {
        url = url + '?openid=' + this.openid + '&headimgurl=' + this.headimgurl + '&nickname=' + this.nickname + '&authback=1'
      }
      window.location.href = url
    }
  }
}
</script>

<style scoped lang="less">
.contnet {
  // width: 700px;
  margin: 0 auto;
}
.authBtn {
  margin: 30px 10% 0;
  padding: 20px;
  word-break: break-all;
  text-align: left;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  background-color: #ee2e2e;
}
.doneBtn {
  margin: 60px 10% 0;
  padding: 10px;
  word-break: break-all;
  text-align: center;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  background-color: #ee2e2e;
}
</style>
