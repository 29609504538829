import request from '@/utils/edu_request.js'

export function getWxCodeOpenid(params) {
  return request({
    url: '/distribtn/index/getWxCodeOpenid',
    method: 'get',
    params
  })
}
// 获取分享配置
export function wxshareInfo(params) {
  return request({
    url: '/distribtn/index/wxshareInfo',
    method: 'get',
    params
  })
}
